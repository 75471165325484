import React, {useMemo} from 'react';

import Component from '@scout-web/shared/components/AuthHeader';

function AuthHeader(props) {
  const vendorName = useMemo(
    () => props.vendorName.toLowerCase(),
    [props.vendorName],
  );
  return <Component {...props} vendorName={vendorName} />;
}

export default AuthHeader;
