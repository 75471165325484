import AuthHeader from './AuthHeader.json';
import Camera from './Camera.json';
import Mode from './Mode.json';
import SignupHeader from './SignupHeader.json';

export default {
  AuthHeader,
  Camera,
  Mode,
  SignupHeader,
};
