import React from 'react';
import {createRoot} from 'react-dom/client';
import SharedApp from '@scout-web/shared';

import reportWebVitals from './reportWebVitals';

import components from './components';
import './styles.css';
import translations from './translations';

const config = require(`./${process.env.REACT_APP_CONFIG_FILE || 'config.json'}`);

const app = new SharedApp({
  ...config,
  components,
  translations,
});
const App = app.build();

createRoot(document.getElementById('root')).render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export default App;